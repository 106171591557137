<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-avatar
            ref="previewEl"
            rounded
            :text="avatarText(userData.firstName)"
            :src="
              userData.avatarFileUrl
                ? userData.avatarFileUrl.startsWith('data:image')
                  ? userData.avatarFileUrl
                  : `data:image/jpeg;base64,${userData.avatarFileUrl}`
                : ''
            "
            size="80px"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->

        <div class="d-flex flex-wrap">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon ml-0"
            @click="$refs.refInputEl.click()"
          >
            <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="inputImageRenderer"
            />

            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-secondary"
            class="btn-icon ml-0"
            @click="deletePic"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2" @submit="onSubmit">
      <b-row>
        <b-col sm="6">
          <b-form-group label="Name" label-for="account-username">
            <b-form-input
              v-model="userData.firstName"
              placeholder="Name"
              name="username"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Last Name" label-for="account-name">
            <b-form-input
              v-model="userData.lastName"
              name="name"
              placeholder="Last Name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="E-mail" label-for="account-e-mail">
            <b-form-input
              v-model="userData.email"
              name="email"
              placeholder="Email"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        variant="success"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        type="submit"
      >
        Save
      </b-button>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";
import { avatarText } from "@core/utils/filter";
import { Vue } from "vue-property-decorator";

export default {
  components: {
    BButton,
    ToastificationContent,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    getData: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: store.state.user.user,
      profileFile: null,
    };
  },
  computed: {
    normalizedSize() {
      return tthis.getData();
    },
  },
  methods: {
    resetForm() {
      this.optionsLocal = store.state.user.user;
    },
    deletePic() {
      store
        .dispatch("app-account/deletePic", {
          id: this.userData.id,
        })
        .then((response) => {
          this.userData.avatarFileUrl = "";
          this.$store.dispatch("user/fetchUser", { id: this.userData.id });
          //router.go();
          //this.normalizedSize();
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(
            newFile.response.data.errors
          )) {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: "error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: value,
              },
            });
          }
        });
    },
  },
  setup(props) {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const onSubmit = (event) => {
      event.preventDefault();

      const blankUserData = {
        id: props.userData.id,
        firstName: props.userData.firstName,
        lastName: props.userData.lastName,
        email: props.userData.email,
        userRoleIds: [props.userData.userRoles[0].roleId],
      };

      store
        .dispatch("app-account/editUser", blankUserData)
        .then((r) => {
          store.dispatch("user/fetchUser", { id: props.userData.id });
          //this.normalizedSize();
          // router.go();
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: "User Updated",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(
            newFile.response.data.errors
          )) {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: "error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: value,
              },
            });
          }
        });
    };

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign

        props.userData.avatarFileUrl = base64;

        store
          .dispatch("app-account/addPhoto", {
            id: props.userData.id,
            base64: refInputEl.value.files[0],
          })
          .then((response) => {
            //this.normalizedSize();
            store.dispatch("user/fetchUser", { id: props.userData.id });
            //router.push("/pages/account-setting").catch(() => {});
            // router.go();
          })
          .catch((error) => {
            for (const [key, value] of Object.entries(
              newFile.response.data.errors
            )) {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: "error",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: value,
                },
              });
            }
            if (error.response.status === 404) {
              skillsData.value = undefined;
            }
          });
      }
    );

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
      onSubmit,
      avatarText,
    };
  },
};
</script>
